import React, {useContext } from 'react';
import { AiFillPlayCircle } from "react-icons/ai";
import { SiEthereum } from "react-icons/si";
import { BsInfoCircle } from "react-icons/bs";
import { Loader } from ".";
import { TransactionContext, TransactionProvider } from '../context/TransactionsContex';
import { shortenAddress } from '../utils/shortenAddress';

const commonStyles = "min-h-[70px] sm:px-0 px-2 sm:min-w-[120px] flex justify-center items-center border-[0.5px] border-gray-400 text"

const Input = ({ placeholder, name, type, value, handleChange }) =>(
    <input placeholder={placeholder}
    type={type}
    step="0.0001"
    value={value}
    onChange={(e) => handleChange(e, name)}
    className="my-2 w-full rounded-sm p-2 outline-none bg-transparent text-white border-none text-sm white-glassmorphism"
    />
)

const Welcome = () => {

    const { connectWallet, currentAccount, formData, sendTransaction, handleChange, isLoading } = useContext(TransactionContext);


    const handleSubmit = (e) => {

        const { addressTo, amount, keyword, message } = formData;
        e.preventDefault()

        if(!addressTo || !amount || !keyword || !message) return;

        sendTransaction();

    }

    return (
                <div className="flex w-full justify-center items-center">
                    <div className="flex md:flex-row flex-col items-start justify-between md:p-20 py-12 px-4">
                        <div className="flex flex-1 justify-start flex-col md:mr-10">
                            <h1 className="text-5xl	sm:text-5x1 text-white text-gradient py-2">
                                Send Crypto <br /> across the world
                            </h1>
                            <p className="text-left mt-5 text-white font-light md:w-9/12 w-11/12 text-base">
                                Explore the crypto world. Buy and sell cryptocurrencies easily on krypto
                            </p>
                    {!currentAccount && (
                        <button type="button" onClick={connectWallet}
                            className="flex flex-row justify-center items-center my-5 bg-[#2952e3] p-3 rounded-full cursor-pointer hover:bg-[#2546b]"
                        >
                            <p className="text-white text-base font-semibold">Connect Wallet</p>
                        </button>
                    )}


                            <div className="grid sm:grid-cols-3 grid-cols-2 w-full mt-10">
                                <div className={`rounded-t1-2x1 text-white ${commonStyles}`} >
                                    Reliability
                                </div>
                                <div className={`rounded-t1-2x1 text-white ${commonStyles}`} >
                                Security
                                </div>

                                <div className={`rounded-tr-2x1 text-white ${commonStyles}`}>
                                    Ethereum
                                </div>
                                <div className={`rounded-b1-2x1 text-white ${commonStyles}`}> 
                                    web 3.0
                                </div>
                                <div className={`rounded-b1-2x1 text-white ${commonStyles}`}> 
                                    Low Fees
                                </div>

                                <div className={`rounded-b1-2x1 text-white ${commonStyles}`}>
                                    blockchain
                                </div>
                            </div>
                        </div>
                       
                        <div className="flex flex-col flex-1 items-center justify-start w-full md:mt-0 mt-10">
                            <div className="p-3 justify-end items-start flex-col rounded-x1 h-40 sm:w-72 w-full my-5 eth-card white-glassmorpism">
                                <div className="flex justity-beyween flex-col w-full h-full">
                                <div className="flex justify-between items-start">
                                        <div className="w-10 h-10 rounded-full border-2 border-white flex justify-center">
                                            <SiEthereum fontSize={21} color="#fff" />
                                        </div>
                                        <BsInfoCircle fontSize={17} color="#fff" />
                                    </div> 
                                    <div>
                                        <br/>
                                        <p className="text-white font-light text-sm">
                                            {shortenAddress(currentAccount)}
                                        </p>
                                        <p className="text-white font-semibold  text-lg mt-1">
                                        Ethereum
                                        </p>
                                    </div>
                                </div>
                            </div>
                        <div className="p-5 sm:w-96 w-full flex-1 flex-col justify-start items-center blue-glassmorphism">
                            <Input placeholder="Address To"  name="addressTo" type="text" handleChange={handleChange}/>
                            <Input placeholder="Amount (ETH"  name="amount" type="number" handleChange={handleChange}/>
                            <Input placeholder="Keyword (Gif)"  name="keyword" type="text" handleChange={handleChange}/>
                            <Input placeholder="Enter Message"  name="message" type="text" handleChange={handleChange}/>

                        <div className="h-[2px] w-full bg-gray-400 "/>
                        
                        { isLoading ? (
                            <Loader />
                        ): (
                            <button type="button"
                            onClick={handleSubmit}
                            className="text-white mt-2 w-full border-[1px] p-2 border-[#3d4f7c] rounded-full cursor-pointer"
                            >
                            Send Now
                            </button>
                        )}
                    </div>
                </div>
                
            </div>
       </div>       
      
    )
}
export default Welcome;